import '../styles/CancelReservation.css';
import { useNavigate, useLocation } from 'react-router-dom';

// import { Client, DataHour } from '../components/Interfaces';

const CancelReservation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clientData } = location.state;
  const { id } = location.state;

  const toHome = () => {
    window.location.replace(`https://${process.env.REACT_APP_URL_WEB}`);
  };
  const toReserve = () => {
    navigate('/filter', { state: { message: `${id}`, clientData } });
  };

  return (
    <div className="appBody">
      <div className="box">
        <div className="thisTitle"><b>Su hora ha sido cancelada con éxito</b></div>
        <div className="buttons">
          <button className='buttonCancelReservation' type="submit" onClick={toHome}>Volver al inicio</button>
          <button className='buttonCancelReservation' type="submit" onClick={toReserve}>Reservar otra hora </button>
        </div>
      </div>
    </div>
  );
};

export default CancelReservation;
