import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import RutContainer from '../components/RutContainer';
import '../styles/RutPage.css';
import Loader from '../components/Loader';
import { Client } from '../components/Interfaces';
import { ClipLoader } from 'react-spinners';
import { formattingForeignSS } from '../utils/formattingForeignSS';
import { useFetchGet } from '../utils/fetchData/fetchData';

const baseURL = process.env.REACT_APP_BASEURL;
const authorization = process.env.REACT_APP_AUTHORIZATION;

export default function RutPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const params: URLSearchParams = new URLSearchParams(window.location.search);
  const uid: string | null = params.get('uid');
  const id: string | null = params.get('id');
  const { data: dataISOcountries } = useFetchGet(`${baseURL}/alma/external/pp/v1/countries`);

  const setRutFromRutContainer = (thisRut: string, documentType: string, nacionalidad?: string) => {
    setIsLoading(true);
    searchByRut(thisRut, documentType, nacionalidad ?? '');
  };

  const searchByRut = (searchRut: string, documentType: string, nacionalidad: string) => {
    const formattedRut = documentType === 'cedula' ? searchRut :
      formattingForeignSS(searchRut, dataISOcountries, nacionalidad).ISOpassport;

    axios.post(`${baseURL}/alma/external/pp/v1/patientData`,
      { rut: formattedRut },
      {
        headers: {
          Authorization: authorization,
          'Content-Type': 'multipart/form-data',
        },
      }).then((response: any) => {
        if (response) {
          const clientData = response.data[0]
          const data: Client = {
            nombre: clientData.nombre,
            paterno: clientData.paterno,
            materno: clientData.materno,
            correo: clientData.correo,
            celular: clientData.celular,
            sexo: clientData.sexo,
            direccion: clientData.direccion,
            comuna: clientData.comuna,
            fechaNacimiento: clientData.fecha_nacimiento,
            edad: clientData.edad,
            prevision: clientData.prevision,
            nacionalidad: clientData.nacionalidad,
            ocupacion: clientData.occupation,
            concentimiento: clientData.concentimiento,
            rut: clientData.ss,
            ss: clientData.ss,
            confirmacion_correo: clientData.confirmacion_correo,
            pasaporte: documentType == 'pasaporte' ? true : false,
          };
          setIsLoading(false);
          navigate('/filter', { state: { message: `${id}`, clientData: data, uid } });
        }
      }).catch(() => {
        const data: Client = {} as Client;
        data.rut = searchRut;
        data.ss = searchRut;
        data.pasaporte = documentType == 'pasaporte' ? true : false;
        setIsLoading(false);
        navigate('/filter', { state: { message: `${id}`, clientData: data, uid } });
      });
  };

  return (
    <div className="appBody">
      <div className="hourReservationContainer">

        {isLoading
          ? (<ClipLoader size={35} color="var(--background-color)" />) : <RutContainer setRutFromThis={setRutFromRutContainer} />}
      </div>
    </div>
  );
}
