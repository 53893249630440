import axios from 'axios';
import { Client } from '../components/Interfaces';

const timestamp = new Date();
const formattedTimestamp = `${timestamp.getHours()}:${timestamp.getMinutes()}:${timestamp.getSeconds()} ${timestamp.getDate()}/${timestamp.getMonth() + 1}/${timestamp.getFullYear()}`;

const codigoVerificacionHTML = (
  clientData: Client,
  codigo: number | string,
) => (`
<!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office">
  <p style="opacity: 0; height: 1px; margin: 0;">Tu código de verificación es: ${codigo}</p>
<head>
  <title>Reserva de Horas | Almamedica.cl</title>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0 " />
  <meta name="format-detection" content="telephone=no" />
  <style>
    body {
      font-family: 'Montserrat', sans-serif;
      font-weight: lighter;
    }
  </style>
</head>

<body style="margin: 0px;">
  <table width="100%" style="margin: 0px;" align="center" border="0" cellspacing="0" cellpadding="0"
    class="em_main_table">
    <tbody>
      <tr>
        <td align="center" valign="top">
          <div style="width: 650px; margin: 0 5%; text-align: center; margin-bottom: 20px; margin-left: 1%;">
            <table width="90%" border="0" cellspacing="0" cellpadding="0" align="center">
              <tbody>
                <tr>
                  <td style="width: 100%; text-align: center; padding-top: 2%; padding-bottom: 12px;">
                    <span
                      style="color: ${process.env.REACT_APP_BACKGROUND_COLOR}; font-size: 25px; font-weight: 500;">
                      ¡Bienvenido a ${process.env.REACT_APP_COMPANY_NAME}!
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 90%; margin: 0 auto; text-align: center; margin-right: 5%; padding-top: 2%;">
                    <img width="450" height="200" src="https://img.freepik.com/foto-gratis/doctor-paciente_1421-64.jpg">
                  </td>

                </tr>
              </tbody>
            </table>
            <p style="opacity: 0; height: 1px; margin: 0;">${formattedTimestamp}</p>
            <table width="390" align="center" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td style="width: 100%; text-align: center; font-size: 18px; padding-top: 20px; padding-bottom: 20px;">
                    <span style="color: #3e3e3e;">Estimado(a) <b>${clientData.nombre} ${clientData.paterno} ${clientData.materno},</b></span>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center;  padding-bottom: 20px;">
                    <span style="color: #0086c0; font-size: 16px; font-weight:500;">
                      ¡Estas a un paso de reserva tu hora!
                    </span>
                  </td>
                </tr>
                <table width="390px" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="border-radius: 20px; overflow: hidden;">
                  <tbody style="background-color: ${process.env.REACT_APP_BACKGROUND_COLOR}; border-radius: 20px; ">
                    <div style="border-radius: 20px;">
                      <tr>
                        <td style="padding-top: 2%;">
                          <span
                            style="color: white; font-weight: bold; font-size: 25px; padding: 5px; display: block;">Tu código de verificación es:</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span
                            style="color: white; font-weight: bold; font-size: 35px; padding: 5px; display: block; margin-bottom: 2%;">${codigo}</span>
                        </td>
                      </tr>
                    </div>
                  </tbody>
                </table>
                <p style="opacity: 0; height: 1px; margin: 0;">${formattedTimestamp}</p>
                <table width="390" align="center" border="0" cellspacing="0" cellpadding="0"
                  style="border-radius: 0px; overflow: hidden;">
                  <tbody>
                    <div style="margin-top: 0px;">
                      <tr>
                        <td style="text-align: center; padding-top: 20px;">
                          <span style="color: #0086c0; font-size: 16px; font-weight: 500;">
                            Agradecemos tu preferencia.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; padding-top: 2%; padding-bottom: 20px;">
                          <span style="color: #8f8f8e; font-size: 14px; font-weight: lighter">
                            Este correo ha sido enviado de forma automática, por favor no responda.
                          </span>
                        </td>
                      </tr>
                      <tr>
                      <td style="text-align: center; background-color: ${process.env.REACT_APP_BACKGROUND_COLOR};">
                        <a href="https://${process.env.REACT_APP_URL_WEB}" style="color: white; font-size: 25px; font-weight:bold; text-align: center; text-decoration: none;">
                          ${process.env.REACT_APP_URL_WEB}
                        </a>
                      </td>
                      </tr>
                    </div>
                  </tbody>
                </table>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <p style="opacity: 0; height: 1px; margin: 0;">${formattedTimestamp}</p>
</body>

<!-- <td
                style="background-color: #40a534; height: auto; width: 60%; margin-left: auto; margin-right: auto; margin-top: 5%; padding-top: 3%; padding-bottom: 3%; margin-bottom: 5%;">
                <span style="color: white; font-weight: bold; font-size: 30px; padding: 10px; display: block;">Código de
                  reserva</span>
                <span
                  style="color: white; font-weight: bold; font-size: 30px; padding: 10px; display: block; margin-bottom: 3%;">2029015</span>
                <div style="width: 90%; margin: 0 auto; text-align: center; margin-bottom: 0px; height: 45px; ">
                  <div style="float: left; width: 10%; text-align: center;">
                    <i style="font-size: 30px; color: white; margin-top: 7.5px;" class="fa-solid fa-user-doctor"></i>
                  </div>
                  <div style="float: left; width: 88%; text-align: left; margin-left: 2%;">
                    <div style="color:white; margin-bottom: 5px;">Profesional</div>
                    <div style="color: white;">Dr. Jorge Marvez</div>
                  </div>
                </div>
                <hr style="border-top: 1px solid #5bb543; margin-top: 6px; margin-left: 16%; margin-bottom: 6px;"
                  width="75%" /> -->

<!-- <ul
            style="font-family: Open Sans, Arial, sans-serif; font-size: 19px; line-height: 20px; color: #5b5b5b; font-weight: 400; list-style-type: none; width: 100%; padding-bottom: 25px; border-radius: 20px; padding-top: 0px; padding-left: 0;">
            <li style="margin-bottom: 0px; border-radius: 20px; margin-top: 10px; text-align: center;">
              <span style="color: #3e3e3e;">Estimado(a) <b>${clientData.nombre} ${clientData.paterno} ${clientData.materno},</b></span>
            </li>
            <li style="margin-bottom: 0px; border-radius: 20px; margin-top: 15px; text-align: center;">
              <span style="color: #3e3e3e;">La reserva de tu hora ha sido agendada con éxito para</span>
            </li>
            <li style="margin-bottom: 0px; border-radius: 20px; text-align: center;">
              <span style="color: #3e3e3e; font-size: 18px; font-weight: bold;">Consulta Medicina General.</span>
            </li>

            <li
              style="margin-bottom: auto; background-color: #40a534; border-radius: 20px; height: auto; width: 80%; margin-left: auto; margin-right: auto; margin-top: 5%; padding-top: 3%; padding-bottom: 3%; margin-bottom: 5%;">
              <span style="color: white; font-weight: bold; font-size: 30px; padding: 10px; display: block;">Código de
                reserva</span>
              <span
                style="color: white; font-weight: bold; font-size: 30px; padding: 10px; display: block; margin-bottom: 3%;">2029015</span>
              <div style="width: 90%; margin: 0 auto; text-align: center; margin-bottom: 0px; height: 45px; ">
                <div style="float: left; width: 10%; text-align: center;">
                  <i style="font-size: 30px; color: white; margin-top: 7.5px;" class="fa-solid fa-user-doctor"></i> -->
<!-- <i style="font-size: 30px; color: white; margin-top: 7.5px;" class="fa-light fa-user-doctor"></i> -->


<!--//Footer-->
<!-- </body> -->
</html>
    `);

export default codigoVerificacionHTML;