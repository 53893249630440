
import { ClientSubmit, DataHour, DataLabels } from '../components/Interfaces';

const timestamp = new Date();
const formattedTimestamp = `${timestamp.getHours()}:${timestamp.getMinutes()}:${timestamp.getSeconds()} ${timestamp.getDate()}/${timestamp.getMonth() + 1}/${timestamp.getFullYear()}`;
const serverName = process.env.REACT_APP_SERVER_NAME;

const comprobanteReservaHTML = (
  clientData: ClientSubmit,
  hourData: DataHour,
  dataLabels: DataLabels,
  reservationDate: string,
  reservationHour: string,
) => {
  let preparacionHTML = '';
  if (hourData.idGroupConsultHour != '5') {
    preparacionHTML = `
      <table width="390px" border="0" cellspacing="0" cellpadding="0" align="center" style="border-radius: 20px; overflow: hidden;">
        <tbody style="background-color: ${process.env.REACT_APP_BACKGROUND_COLOR}; border-radius: 10px;">
          <div style="border-radius: 20px; height: 30px;">
            <tr>
              <td>
                <div style="width: 90%; margin: 0 auto; text-align: center; margin-bottom: 0px; height: 45px;">
                  <div style="float: left; width: 88%; text-align: left; margin-left: 2%; padding-top: 5px;">
                    <div style="color: white;">Preparación: ${hourData.preparacion}</div>
                  </div>
                </div>
              </td>
            </tr>
          </div>
        </tbody>
      </table>
    `;
  }
  return `
<!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <title>Reserva de Horas | Almamedica.cl</title>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0 " />
  <meta name="format-detection" content="telephone=no" />
  <style>
    body {
      font-family: 'Montserrat', sans-serif;
      font-weight: lighter;
    }
  </style>
</head>

<body style="margin: 0px;">
  <table width="100%" style="margin: 0px;" align="center" border="0" cellspacing="0" cellpadding="0"
    class="em_main_table">
    <tbody>
      <tr>
        <td align="center" valign="top">
          <div style="width: 650px; margin: 0 1%; text-align: center; margin-bottom: 20px; margin-left: 1%; padding-top: 20px;">
            <table width="90%" border="0" cellspacing="0" cellpadding="0" align="center">
              <tbody>
                <tr>
                  <td style="text-align: center">
                    <img width="10%" style="font-size: 30px; color: white"
                      src="https://cdn-icons-png.freepik.com/512/5610/5610944.png" alt="Profesional">
                  </td>
                </tr>
                <tr>
                  <td style="width: 100%; text-align: center; padding-top: 2%;">
                    <span
                      style="font-family: Open Sans, Arial, sans-serif; color: ${process.env.REACT_APP_BACKGROUND_COLOR}; font-size: 22px; font-weight: 500; margin-bottom: 20px;">
                      ¡Tu reserva de hora fue exitosa!
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 90%; margin: 0 auto; text-align: center; margin-right: 5%; padding-top: 2%;">
                    <img width="450" height="200" src="https://img.freepik.com/foto-gratis/doctor-paciente_1421-64.jpg">
                  </td>

                </tr>
              </tbody>
            </table>
            <p style="opacity: 0; height: 1px; margin: 0;">${formattedTimestamp}</p>
            <table width="390" align="center" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td style="width: 100%; text-align: center; font-size: 18px; padding-top: 30px; padding-bottom: 20px;">
                    <span style="color: #3e3e3e;">Estimado(a) <b>${clientData.nombre} ${clientData.paterno} ${clientData.materno},</b></span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 100%; text-align: center; font-size: 18px;">
                    <span style="color: #3e3e3e;">La reserva de tu hora ha sido agendada con éxito para</span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 100%; text-align: center; font-size: 20px; padding-bottom: 20px;">
                    <span style="color: #3e3e3e; font-size: 18px; font-weight: bold;">${dataLabels.category.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())}.</span>
                  </td>
                </tr>
                <table width="390px" border="0" cellspacing="0" cellpadding="0" align="center"
                  style="border-radius: 20px; overflow: hidden;">
                  <tbody style="background-color: ${process.env.REACT_APP_BACKGROUND_COLOR}; border-radius: 20px; ">
                    <div style="border-radius: 20px;">
                      <tr>
                        <td style="padding-top: 2%;">
                          <span
                            style="color: white; font-weight: bold; font-size: 30px; padding: 5px; display: block;">Código
                            de reserva</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span
                            style="color: white; font-weight: bold; font-size: 30px; padding: 5px; display: block; margin-bottom: 2%; text-decoration: none;">${hourData.reservationCode}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style="width: 90%; margin: 0 auto; text-align: center; margin-bottom: 0px; height: 45px; ">
                            <div style="float: left; width: 10%; text-align: center;">
                              <img width="80%" style="font-size: 30px; color: white; margin-top: 7.5px;"
                                src="https://flaticons.net/icon.php?slug_category=people&slug_icon=doctor&icon_size=210&icon_color=FFFFFF&icon_flip=&icon_rotate=0"
                                alt="Profesional">
                            </div>
                            <div style="float: left; width: 88%; text-align: left; margin-left: 2%;">
                              <div style="color:white; margin-bottom: 5px;">Profesional</div>
                              <div style="color: white;">${hourData.profesionalName}</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 100%; text-align: center;">
                          <hr style="border-top: 1px solid white; width: 75%; margin-left: 15%;" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style="width: 90%; margin: 0 auto; text-align: center; margin-bottom: 0px; height: 45px; ">
                            <div style="float: left; width: 10%; text-align: center;">
                              <img width="90%" style="font-size: 30px; color: white; margin-top: 7.5px;"
                                src="https://flaticons.net/icon.php?slug_category=medical&slug_icon=stethoscope-02"
                                alt="Profesional">
                            </div>
                            <div style="float: left; width: 88%; text-align: left; margin-left: 2%;">
                              <div style="color:white; margin-bottom: 5px;">Prestación</div>
                              <div style="color: white;">${dataLabels.category.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())}</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 100%; text-align: center;">
                          <hr style="border-top: 1px solid white; width: 75%; margin-left: 15%;" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style="width: 90%; margin: 0 auto; text-align: center; margin-bottom: 0px; height: 45px; ">
                            <div style="float: left; width: 10%; text-align: center;">
                              <img width="80%" style="font-size: 30px; color: white; margin-top: 7.5px;"
                                src="https://flaticons.net/icon.php?slug_category=miscellaneous&slug_icon=calendar"
                                alt="Profesional">
                            </div>
                            <div style="float: left; width: 88%; text-align: left; margin-left: 2%;">
                              <div style="color:white; margin-bottom: 5px;">Fecha</div>
                              <div style="color: white;">${reservationDate}</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 100%; text-align: center;">
                          <hr style="border-top: 1px solid white; width: 75%; margin-left: 15%;" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div
                            style="width: 90%; margin: 0 auto; text-align: center; margin-bottom: 0px; height: 45px;">
                            <div style="float: left; width: 10%; text-align: center;">
                              <img width="80%" style="font-size: 30px; color: white; margin-top: 7.5px;"
                                src="https://flaticons.net/icon.php?slug_category=science-technology&slug_icon=clock-02"
                                alt="Profesional">
                            </div>
                            <div style="float: left; width: 88%; text-align: left; margin-left: 2%;">
                              <div style="color:white; margin-bottom: 5px;">Hora</div>
                              <div style="color: white;">${reservationHour} hrs</div>
                            </div>
                          </div>
                        </td>

                      </tr>
                      <tr>
                        <td style="width: 100%; text-align: center;">
                          <hr style="border-top: 1px solid white; width: 75%; margin-left: 15%;" />
                        </td>
                      </tr>
                      <tr>
                        <td style="padding-bottom: 15px;">
                          <div
                            style="width: 90%; margin: 0 auto; text-align: center; margin-bottom: 0px; height: 45px;">
                            <div style="float: left; width: 10%; text-align: center;">
                              <img style="font-size: 30px; color: white; margin-top: 5px;" width="90%"
                                src="https://flaticons.net/icon.php?slug_category=medical&slug_icon=hospital-03-wf"
                                alt="Profesional">
                            </div>
                            <div style="float: left; width: 88%; text-align: left; margin-left: 2%;">
                              <div style="color:white; margin-bottom: 5px;">Sucursal</div>
                              <div style="color: white;">${process.env.REACT_APP_COMPANY_NAME} ${dataLabels.facility.name}</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </div>
                  </tbody>
                </table>
                ${preparacionHTML}
                <p style="opacity: 0; height: 1px; margin: 0;">${formattedTimestamp}</p>
                <table width="390" align="center" border="0" cellspacing="0" cellpadding="0"
                  style="border-radius: 0px; overflow: hidden;">
                  <tbody>
                    <div style="margin-top: 30px;">
                      <tr>
                        <td style="text-align: center;">
                          <span style="color: #0086c0; font-size: 16px; font-weight: 500;">
                            Te enviaremos un correo de confirmación y/o anulación
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; padding-bottom: 10px;">
                          <span style="color: #0086c0; font-size: 16px; font-weight: 500">
                            de tu cita 48 horas antes.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; padding-top: 2%;">
                          <span style="color: #767676; font-size: 16px; font-weight: 500;">
                            Recuerda asistir con tu carnet de identidad o pasaporte.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; padding-bottom: 10px;">
                          <span style="color: #767676; font-size: 16px; font-weight: 600; ">
                            20 minutos antes de tu cita agendada.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; padding-top: 2%;">
                          <a href="${serverName}/?id=${hourData.idGroupConsultHour}/">
                          <button
                            style="color: #0086c0; font-size: 16px; font-weight: 600; background-color: #0086c0; border: none; border-radius: 20px; padding: 10px; color: white; width: 50%;">
                            Reagendar
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; padding-top: 2%; padding-bottom: 10px;">
                          <a href="${serverName}/annulment?rut=${clientData.rut}&id=${hourData.reservationCode}">
                          <button
                            style="color: #0086c0; font-size: 16px; font-weight: 600; background-color: white; border: 2px solid #40a534; border-radius: 20px; padding: 10px; color:#40a534; width: 50%;">
                            Anular hora
                          </button>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; padding-top: 2%;">
                          <span style="color: #0086c0; font-size: 16px; font-weight: 500;">
                            <b>Importante: </b> Si tu reserva no es confirmada, será
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center;">
                          <span style="color: #0086c0; font-size: 16px; font-weight: 500;">
                            asignada a otro paciente.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; padding-top: 2%; padding-bottom: 20px;">
                          <span style="color: #8f8f8e; font-size: 14px; font-weight:400 ; font-style: italic;">
                            Este correo ha sido enviado de forma automática, por favor no responda.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; background-color: ${process.env.REACT_APP_BACKGROUND_COLOR};">
                          <a href="https://${process.env.REACT_APP_URL_WEB}" style="color: white; font-size: 25px; font-weight:bold; text-align: center; text-decoration: none;">
                            ${process.env.REACT_APP_URL_WEB}
                          </a>
                        </td>
                      </tr>
                    </div>
                  </tbody>
                </table>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <p style="opacity: 0; height: 1px; margin: 0;">${formattedTimestamp}</p>
</body>

</html>
    `
};

export default comprobanteReservaHTML;