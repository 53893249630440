import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import arraySupport from 'dayjs/plugin/arraySupport';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import MyDatePicker from './MyDatePicker';
import comprobanteReservaHTML from '../constants/combrobanteReservaHTML';
import codigoVerificacionHTML from '../constants/codigoVerificacionHTML';
import {
  ClientDataContainerProps, DataHour, DataLabels, ClientSubmit, Client,
} from './Interfaces';
import paises from '../constants/Countries';
import Select from 'react-select';
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import Popup from 'reactjs-popup';
import { IoIosAlert } from "react-icons/io";
import { ClipLoader } from 'react-spinners';
import ReCAPTCHA from "react-google-recaptcha";
import { sleep } from '../utils/utils';
import { formattingForeignSS } from '../utils/formattingForeignSS';
import Checkbox from '@mui/material/Checkbox';
import { useFetchGet } from '../utils/fetchData/fetchData';

const baseURL = process.env.REACT_APP_BASEURL;
const varifaliaURL = process.env.REACT_APP_VERIFALIA_URL as string;
const authorization = process.env.REACT_APP_AUTHORIZATION;

const phoneFormatter = (phone: string) => {
  if (phone?.includes('+569')) {
    return phone.slice(4);
  } if (phone?.length === 9) {
    return phone.slice(1);
  } if (phone?.length > 9 && phone?.includes('569')) {
    return phone.slice(3);
  }
  if (!phone) {
    return '';
  }
  return phone;
};

const dateFormatter = (date: string) => {
  dayjs.extend(arraySupport);
  if (date === '') {
    return dayjs().toDate();
  }
  if (date === undefined) {
    return dayjs().toDate();
  }

  if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    const [year, month, day] = date?.split('-');
    return dayjs([+year, +month - 1, +day]).toDate();
  }

  const [day, month, year] = date?.split('-');
  return dayjs([+year, +month - 1, +day]).toDate();
};

const dateFormatterNotification = (date: any) => {
  const fecha = dayjs(date).locale('es');

  const hora = fecha.format('HH');
  const minuto = fecha.format('mm');
  const dia = fecha.format('dddd');
  const month = fecha.format('MMMM');
  const dayNumber = fecha.date();
  const capitalizedDay = dia.charAt(0).toUpperCase() + dia.slice(1);
  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

  const reservationDate = `${capitalizedDay} ${dayNumber} de ${capitalizedMonth}`;
  const reservationHour = `${hora}:${minuto}`;

  return { reservationDate, reservationHour };
};

const ClientDataContainer = ({
  clientData,
  clientExists,
  dataHour,
  dataLabels,
}: ClientDataContainerProps) => {
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [previsiones, setPrevisiones] = useState([]);
  const [comunas, setComunas] = useState([]);
  const navigate = useNavigate();
  const { reservationDate, reservationHour } = dateFormatterNotification(dataHour.reservationHour);
  const [verificarMail, setVerificarMail] = useState<boolean>(false);
  const [verificatiorNumber, setVerificatorNumber] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validatingMail, setValidatingMail] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [repeatedCodeHour, setRepeatedCodeHour] = useState<any>(null);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [userPermission, setUserPermission] = useState<boolean>(true);
  const { data: dataISOcountries } = useFetchGet(`${baseURL}/alma/external/pp/v1/countries`);
  const [emailVerified, setEmailVerified] = useState<boolean>(clientData.confirmacion_correo === 1);
  const [currentEmail, setCurrentEmail] = useState<string>(clientData.correo || '');
  const [verificationStep, setVerificationStep] = useState<'initial' | 'code_sent' | 'verified'>(clientData.confirmacion_correo === 1 ? 'verified' : 'initial');
  const [canResend, setCanResend] = useState<boolean>(true);
  const [resendTimer, setResendTimer] = useState<number>(0);

  const ReservationSchema = Yup.object().shape({
    nombre: Yup.string()
      .required('Campo requerido'),
    paterno: Yup.string()
      .required('Campo requerido'),
    materno: Yup.string()
      .required('Campo requerido'),
    correo: Yup.string()
      .email('Correo inválido')
      .required('Campo requerido')
      .test('verificar',
        async (field, { createError, path }) => {
          // const isValid = await verifaliaEmail(field.valueOf());
          // if (!isValid) return createError({ path, message: 'El correo electrónico no es válido' });
          if (
            (!clientExists || field.valueOf() !== clientData.correo)
            && !verificarMail
          ) return createError({ path, message: 'El correo requiere validación' });
          return true;
        }),
    direccion: Yup.string()
      .required('Direccion requerida'),
    celular: Yup.number()
      .required('Campo requerido')
      .min(10000000, 'El número debe ser de 8 dígitos')
      .max(99999999, 'El número debe ser de 8 dígitos')
      .typeError('Por favor ingresa números solamente'),
    verificador: Yup.number().when(['verificar', 'correo'],
      ([verificar, correo], field) => (
        verificationStep === 'code_sent' && correo !== clientData.correo
          ? field.required('Campo requerido')
            .typeError('Por favor ingresa números solamente')
            .test('isCorrect',
              'El número ingresado es incorrecto',
              (fieldF) => verifyCode(fieldF?.valueOf()))
          : field
      )),
    concentimiento: Yup.boolean().required('Campo requerido')
  });

  const verifaliaEmail = async (email: string) => {
    if (!email) return false;

    setValidatingMail(true);
    const data = {
      email,
    };
    try {
      const response = await axios.post(varifaliaURL, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: authorization,
        },
      });
      setEmailVerified(true);
      setCurrentEmail(email);
      setValidatingMail(false);
      return true;
    } catch (err) {
      setEmailVerified(false);
      setValidatingMail(false);
      return false;
    }
  }

  const toHome = () => {
    window.location.replace(`https://${process.env.REACT_APP_URL_WEB}`);
  };
  const toReserve = () => {
    navigate('/filter', { state: { message: `${dataHour.idGroupConsultHour}`, clientData } });
  };
  const cancelarHora = () => {
    setIsCancelling(true)
    axios.post(`${baseURL}/alma/external/v1/confirmationWeb`,
      {
        id_agendamiento: repeatedCodeHour,
        status: 2,
        rut: clientData.rut,
      },
      {
        headers: {
          Authorization: authorization,
          'Content-Type': 'multipart/form-data',
        },
      }).catch(
        () => { }
      ).then(() => {
        setIsCancelling(false)
        navigate('/cancel-reservation', { state: { id: `${dataHour.idGroupConsultHour}`, clientData } });
      }
      );
  }

  const getTokenSendinblue = async () => {
    const response = await axios.get(`${baseURL}/alma/external/pp/v1/apiKeyBrevo`,
      {
        headers: {
          Authorization: authorization,
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        return response.data[0].pass_smtp
      }).catch(() => { });

    return response;
  }

  const VerificationEmail = async (
    clientDataF: ClientSubmit,
    hourData: DataHour,
    dataLabelsF: DataLabels,
  ) => {
    const data = {
      sender: { name: process.env.REACT_APP_COMPANY_NAME, email: process.env.REACT_APP_EMAIL_SENDINBLUE },
      to: [{ email: clientDataF.correo, name: 'Comprobante de reserva' }],
      subject: 'Reserva web exitosa',
      htmlContent: comprobanteReservaHTML(
        clientDataF,
        hourData,
        dataLabelsF,
        reservationDate,
        reservationHour
      ),
    };

    const token = await getTokenSendinblue();
    axios.post('https://api.sendinblue.com/v3/smtp/email', data, {
      headers: {
        'api-key': token,
        'Content-Type': 'application/json',
      },
    }).then().catch(() => { });
  };

  const sendVerificationNumber = async (codigo: number | string, correo: string) => {
    const data = {
      sender: { name: process.env.REACT_APP_COMPANY_NAME, email: process.env.REACT_APP_EMAIL_SENDINBLUE },
      to: [{ email: correo, name: 'Correo a verificar' }],
      textContent: `Tu código de verificación es: ${codigo}`,
      subject: 'Código de verificación',
      htmlContent: codigoVerificacionHTML(
        clientData,
        codigo
      ),
    };
    const token = await getTokenSendinblue();
    axios.post('https://api.sendinblue.com/v3/smtp/email', data, {
      headers: {
        'api-key': token,
        'Content-Type': 'application/json',
      },
    }).then().catch(() => { });
  };

  const generateVerificatiorNumber = (correo: string) => {
    const code: number = Math.floor(Math.random() * (999999 - 99999) + 99999);
    setVerificarMail(true);
    setVerificatorNumber(code);
    sendVerificationNumber(code, correo);
  };

  const reserveHour = (
    dataHourF: DataHour,
    clientDataF: ClientSubmit,
    dataLabelsF: DataLabels,
  ) => {
    const [fecha, hora] = dataHourF.reservationHour.split(' ');
    const body = {
      rut: clientDataF.rut,
      id: dataHourF.idProfesional,
      fecha,
      hora,
      facility: dataHourF.facility,
      categorie: dataHourF.categorie,
      id_cajero: dataHourF.uid
    };
    axios.post(`${baseURL}/alma/external/v1/eventsWeb`,
      body,
      {
        headers: {
          Authorization: authorization,
          'Content-Type': 'multipart/form-data',
        },
      }).then((response: any) => {
        dataHourF.reservationCode = response.data.id;
        VerificationEmail(clientDataF, dataHourF, dataLabelsF);
        const prevision: any = previsiones.find(
          (item: any) => item.option_id === clientDataF.prevision,
        );
        const previsionName = prevision ? prevision.title : 'Sin previsión';
        navigate('/reservation-data', {
          state: {
            dataHour: dataHourF,
            clientData: clientDataF,
            dataLabels: dataLabelsF,
            reservationDate,
            reservationHour,
            previsionName,
          },
        });
        setIsLoading(false);
      }).catch((err: any) => {
        if (err.response.data.message === 'No es posible concretar tu reserva, porque ya tienes una cita para la misma prestación.') {
          setIsLoading(false);
          setOpenModal(true);
          setRepeatedCodeHour(err.response.data.id)
        }
      });
  };

  const createUser = (
    dataHourF: DataHour,
    clientDataF: ClientSubmit,
    dataLabelsF: DataLabels,
  ) => {
    const { pasaporte, ISOpassport, isonum } = clientDataF.pasaporte
      ? formattingForeignSS(clientDataF.ss, dataISOcountries, clientDataF.nacionalidad)
      : { pasaporte: null, ISOpassport: clientDataF.rut, isonum: clientDataF.nacionalidad };

    clientDataF.rut = clientDataF.pasaporte ? ISOpassport : clientDataF.rut;

    const body = {
      rut: clientDataF.rut,
      nombre: clientDataF.nombre,
      paterno: clientDataF.paterno,
      materno: clientDataF.materno,
      direccion: clientDataF.direccion,
      comuna: clientDataF.comuna,
      correo: clientDataF.correo,
      sexo: clientDataF.sexo,
      fecha_nacimiento: clientDataF.fechaNacimiento,
      prevision: clientDataF.prevision,
      celular: clientDataF.celular,
      nacionalidad: isonum,
      ocupacion: clientDataF.ocupacion,
      token_app: 'none',
      confirmacion: clientDataF.concentimiento ? 1 : 0,
      pasaporte: pasaporte
    };
    axios.post(`${baseURL}/alma/external/pp/v1/insertPatient`,
      body,
      {
        headers: {
          Authorization: authorization,
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        reserveHour(dataHourF, clientDataF, dataLabelsF);
      }).catch(() => { });
  };

  const isUserChange = (clientOriginal: Client, clientUpdate: ClientSubmit) => {
    if (clientOriginal.nombre !== clientUpdate.nombre) {
      return true;
    } if (clientOriginal.paterno !== clientUpdate.paterno) {
      return true;
    } if (clientOriginal.materno !== clientUpdate.materno) {
      return true;
    } if (clientOriginal.direccion !== clientUpdate.direccion) {
      return true;
    } if (clientOriginal.comuna !== clientUpdate.comuna) {
      return true;
    } if (clientOriginal.correo !== clientUpdate.correo) {
      return true;
    } if (clientOriginal.sexo !== clientUpdate.sexo) {
      return true;
    } if (clientOriginal.fechaNacimiento !== clientUpdate.fechaNacimiento) {
      return true;
    } if (clientOriginal.prevision !== clientUpdate.prevision) {
      return true;
    } if (clientOriginal.celular !== clientUpdate.celular) {
      return true;
    } if (clientOriginal.nacionalidad !== clientUpdate.nacionalidad) {
      return true;
    } if (clientOriginal.concentimiento !== clientUpdate.concentimiento) {
      return true;
    }

    return false;
  };

  const updateUser = (
    dataHourF: DataHour,
    clientDataF: ClientSubmit,
    dataLabelsF: DataLabels,
  ) => {
    const body = {
      rut: clientDataF.rut,
      nombre: clientDataF.nombre,
      paterno: clientDataF.paterno,
      materno: clientDataF.materno,
      direccion: clientDataF.direccion,
      comuna: clientDataF.comuna,
      correo: clientDataF.correo,
      sexo: clientDataF.sexo,
      fecha_nacimiento: clientDataF.fechaNacimiento,
      prevision: clientDataF.prevision,
      celular: clientDataF.celular,
      nacionalidad: clientDataF.nacionalidad,
      ocupacion: clientDataF.ocupacion,
      token_app: 'null',
      confirmacion: clientDataF.concentimiento ? 1 : 0,
      ss: clientDataF.rut,
    };
    axios.post(`${baseURL}/alma/external/pp/v1/updatePatient`,
      body,
      {
        headers: {
          Authorization: authorization,
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        reserveHour(dataHourF, clientDataF, dataLabelsF);
      }).catch((err) => { console.error(err); });
  };
  useEffect(() => {
    if (!dataLoaded) {
      axios.post(`${baseURL}/alma/external/pp/v1/prevision`, '', {
        headers: {
          Authorization: authorization,
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        setPrevisiones(response.data);
        setDataLoaded(true);
      }).catch(() => {
      });

      axios.post(`${baseURL}/alma/external/pp/v1/commune`, '', {
        headers: {
          Authorization: authorization,
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        setComunas(response.data);
        setDataLoaded(true);
      }).catch(() => {
      });
    }
  }, [dataLoaded]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }

    return () => clearInterval(interval);
  }, [resendTimer]);

  const colourStyles = {
    control: (base: any, state: any) => ({
      ...base,
      backgroundColor: 'white',
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
      fontWeight: 'lighter',
      fontSize: '14px',
    }),
    menu: (base: any, state: any) => ({
      ...base,
      backgroundColor: 'white',
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
      fontWeight: 'lighter',
      fontSize: '14px',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#80808060',
      fontWeight: 'lighter',
      fontSize: '14px',
    }),

  };

  const PopUp = () => {
    return (
      <Popup open={openModal} modal closeOnDocumentClick={false}>
        <div className='popUpContainer'>
          <div style={{ display: 'flex', flexDirection: 'row', width: '25%', paddingTop: '3%' }}>
            <IoIosAlert size={40} color='#52A92B' />
            <p className='popUpTitle'>Alerta</p>
          </div>
          <p className='popUpSubtitle'>Lo sentimos. No es posible concretar tu reserva, porque ya tienes una cita para la misma especialidad. El código de su reserva es {repeatedCodeHour}.</p>
          <div className="buttons">
            <div style={{ width: '100%' }}>
              <button disabled={isCancelling} className='containerTopButton' type="submit" onClick={toReserve}>Reservar otra hora</button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
              <button disabled={isCancelling} className='containerBottomButton' type="submit" onClick={toHome}>Volver al inicio</button>
              <button disabled={isCancelling} className='containerBottomButton' type="submit" onClick={cancelarHora}>
                {isCancelling ? <ClipLoader size={14} color="#78cd51" /> : 'Anular hora'}
              </button>
            </div>
          </div>
        </div>
      </Popup>
    )
  }

  const handleChangeCaptcha = (value: any) => {
    if (value)
      setCaptcha(value);
  }

  const handleEmailValidation = async (email: string) => {
    setErrorMessage("");
    if (verificationStep === 'verified') return;

    const isValid = await verifaliaEmail(email);
    if (isValid) {
      if (email === clientData.correo) {
        setEmailVerified(true);
        setVerificationStep('verified');
        setVerificarMail(true);
      } else {
        generateVerificatiorNumber(email);
        setVerificationStep('code_sent');
        setCanResend(false);
        setResendTimer(60);
      }
    } else {
      setErrorMessage("El correo no es válido");
    }
  };

  const ValidateEmailButton = ({ email }: { email: string }) => {
    const isVerified = verificationStep === 'verified';
    const isCodeSent = verificationStep === 'code_sent';
    const isOriginalEmail = email === clientData.correo;

    return (
      <button
        type="button"
        onClick={() => handleEmailValidation(email)}
        disabled={validatingMail || !email || isVerified || !canResend}
        style={{
          padding: '8px 12px',
          backgroundColor: isVerified ? '#cccccc' : validatingMail || !canResend ? '#cccccc' : '#52A92B',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: isVerified || validatingMail || !email || !canResend ? 'not-allowed' : 'pointer',
          marginLeft: '8px',
          marginBottom: '8px',
          fontSize: '12px',
          fontWeight: '600',
          transition: 'all 0.3s ease',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '120px',
          height: '36px'
        }}
      >
        {validatingMail ? (
          <ClipLoader size={14} color="#ffffff" />
        ) : isVerified ? (
          <>
            <span style={{ marginRight: '6px' }}>✓</span>
            Verificado
          </>
        ) : isCodeSent ? (
          resendTimer > 0 ? `Reenviar en ${resendTimer}s` : 'Reenviar código'
        ) : (
          <>
            <span style={{ marginRight: '6px' }}>✓</span>
            Validar correo
          </>
        )}
      </button>
    );
  };

  const verifyCode = (code: number) => {
    if (code === verificatiorNumber) {
      setEmailVerified(true);
      setVerificationStep('verified');
      setVerificarMail(true);
      return true;
    }
    return false;
  };

  return (
    <div className="clientDataContainerH">
      <p className="containerTitleReservation">
        <HiOutlineClipboardDocumentCheck style={{ marginRight: '4%' }} size={34} />
        Reserva de hora
      </p>
      <p className="containerSubTitle">
        {'Por favor ingrese sus datos para continuar con el agendamiento'}
      </p>
      <div className="clientInputDataContainer">
        <Formik
          initialValues={{
            nombre: (clientData.nombre ? clientData.nombre : ''),
            paterno: (clientData.paterno ? clientData.paterno : ''),
            materno: (clientData.materno ? clientData.materno : ''),
            nacimiento: dateFormatter(clientData.fechaNacimiento),
            sexo: (clientData.sexo ? clientData.sexo : 'M'),
            prevision: (clientData.prevision ? clientData.prevision : '0'),
            direccion: (clientData.direccion ? clientData.direccion : ''),
            comuna: (clientData.comuna ? clientData.comuna : ''),
            correo: (clientData.correo ? clientData.correo : ''),
            nacionalidad: (clientData.nacionalidad ? clientData.nacionalidad : 'Chile'),
            celular: `${phoneFormatter(clientData.celular)}`,
            edad: (clientData.edad ? clientData.edad : ''),
            fechaNacimiento: '',
            verificador: '',
            ocupacion: (clientData.ocupacion ? clientData.ocupacion : ''),
            concentimiento: userPermission,
            ss: (clientData.ss ? clientData.ss : ''),
            pasaporte: clientData.pasaporte ?? false,
          }}
          onSubmit={async (values) => {
            setIsLoading(true);
            const client: ClientSubmit = { ...values, concentimiento: values.concentimiento ? 1 : 0 };
            client.fechaNacimiento = dayjs(values.nacimiento).format('YYYY-MM-DD');
            client.rut = clientData.rut;
            client.celular = `+569${values.celular}`;
            const userNeedUpdate = isUserChange(clientData, client);
            await sleep(1000);

            if (!clientExists) {
              // console.log("Debo crear al usuario")
              await createUser(dataHour, client, dataLabels);
            } else if (verificarMail || userNeedUpdate) {
              // console.log("Debo actualizar al usuario")
              await updateUser(dataHour, client, dataLabels);
            } else {
              // console.log("Debo reservar la hora")
              await reserveHour(dataHour, client, dataLabels);
            }

          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={ReservationSchema}
        // validateOnMount
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              {!clientData.nombre
                ? (
                  <>
                    <label className="labelForm"><b>Nombre</b></label>
                    <Field id="nombre" name="nombre" className="fieldForm" placeholder='Ingrese su nombre' />
                    {errors.nombre && touched.nombre ? (<div className="error">{String(errors.nombre)}</div>) : null}
                  </>
                )
                : null}
              {!clientData.paterno
                ? (
                  <>
                    <label className="labelForm"><b>Apellido Paterno</b></label>
                    <Field id="paterno" name="paterno" className="fieldForm" placeholder='Ingrese apellido paterno' />
                    {errors.paterno && touched.paterno ? (<div className="error">{String(errors.paterno)}</div>) : null}
                  </>
                )
                : null}
              {!clientData.materno
                ? (
                  <>
                    <label className="labelForm"><b>Apellido Materno</b></label>
                    <Field id="materno" name="materno" className="fieldForm" placeholder='Ingrese apellido materno' />
                    {errors.materno && touched.materno ? (<div className="error">{String(errors.materno)}</div>) : null}
                  </>
                )
                : null}
              {!clientData.sexo
                ? (
                  <>
                    <label className="labelForm"><b>Sexo</b></label>
                    <Select
                      placeholder="Selecciona un sexo"
                      className="formInput"
                      styles={colourStyles}
                      options={[
                        { value: 'M', label: 'Masculino' },
                        { value: 'F', label: 'Femenino' },
                      ]}
                      onChange={(sexo: any) => {
                        values.sexo = sexo.value;
                      }}
                    />
                  </>
                )
                : null}
              {!clientData.ocupacion
                ? (
                  <>
                    <label className="labelForm"><b>Ocupación</b></label>
                    <Select
                      placeholder="Selecciona una ocupación"
                      className="formInput"
                      styles={colourStyles}
                      options={[
                        { value: 'Trabajador dependiente', label: 'Trabajador dependiente' },
                        { value: 'Trabajador independiente', label: 'Trabajador independiente' },
                        { value: 'Estudiante', label: 'Estudiante' },
                        { value: 'Sin ocupación', label: 'Sin ocupación' }
                      ]}
                      onChange={(ocupacion: any) => {
                        values.ocupacion = ocupacion.value;
                      }}
                    />
                    {errors.ocupacion && touched.ocupacion ? (<div className="error">{String(errors.ocupacion)}</div>) : null}
                  </>
                )
                : null}
              {!clientData.prevision
                ? (
                  <>
                    <label className="labelForm"><b>Previsión</b></label>
                    <Select
                      placeholder="Selecciona una previsión"
                      className="formInput"
                      styles={colourStyles}
                      options={previsiones.map((item: any) => ({ value: item.option_id, label: item.title }))}
                      onChange={(prevision: any) => {
                        values.prevision = prevision.value;
                      }}
                    />
                  </>
                )
                : null}
              {!clientData.nacionalidad
                ? (
                  <>
                    <label className="labelForm"><b>Nacionalidad</b></label>
                    <Select
                      placeholder="Selecciona una nacionalidad"
                      className="formInput"
                      styles={colourStyles}
                      options={paises.map((item: any) => ({ value: item, label: item }))}
                      onChange={(nacionalidad: any) => {
                        values.nacionalidad = nacionalidad.value;
                      }}
                    />
                  </>
                )
                : null}
              {!clientData.direccion
                ? (
                  <>
                    <label className="labelForm"><b>Dirección</b></label>
                    <Field id="direccion" name="direccion" className="fieldForm" placeholder='Ej: General Freire 067' />
                    {errors.direccion && touched.direccion
                      ? (<div className="error">{String(errors.direccion)}</div>)
                      : null}
                  </>
                )
                : null}
              {!clientData.comuna
                ? (
                  <>
                    <label className="labelForm"><b>Comuna</b></label>
                    <Select
                      placeholder="Selecciona una comuna"
                      className="formInput"
                      styles={colourStyles}
                      options={comunas.map((item: any) => ({ value: item.nombre, label: item.nombre }))}
                      onChange={(comuna: any) => {
                        values.comuna = comuna.value;
                      }}
                    />
                  </>
                )
                : null}
              <>
                <label className="labelForm"><b>Fecha de nacimiento</b></label>
                <MyDatePicker label="nacimiento" />
              </>
              <>
                <label className="labelForm"><b>Correo</b></label>
                <div className="dataField">
                  <Field
                    id="correo"
                    name="correo"
                    className="fieldForm"
                    placeholder='correo@ejemplo.com'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const newEmail = e.target.value;
                      setFieldValue('correo', newEmail);
                      if (newEmail !== currentEmail) {
                        setEmailVerified(false);
                        setVerificarMail(false);
                        setVerificationStep('initial');
                      }
                    }}
                  />
                  <ValidateEmailButton email={values.correo} />
                </div>
                {verificationStep === 'code_sent' && values.correo !== clientData.correo && (
                  <div style={{
                    backgroundColor: '#f8f9fa',
                    padding: '15px',
                    borderRadius: '8px',
                    marginTop: '10px',
                    border: '1px solid #e9ecef',
                    marginBottom: '10px'
                  }}>
                    <label htmlFor="verificador" style={{
                      display: 'block',
                      marginBottom: '8px',
                      color: '#495057',
                      fontSize: '14px',
                      fontWeight: '500'
                    }}>
                      <span style={{ marginRight: '8px' }}>🔒</span>
                      <b>Ingresa el código enviado a tu correo</b>
                    </label>
                    <div style={{ position: 'relative' }}>
                      <Field
                        id="verificador"
                        name="verificador"
                        type="number"
                        style={{
                          width: '80%',
                          padding: '12px',
                          fontSize: '18px',
                          letterSpacing: '8px',
                          textAlign: 'center',
                          border: '2px solid #52A92B',
                          borderRadius: '4px',
                          backgroundColor: 'white',
                          outline: 'none',
                          transition: 'all 0.3s ease'
                        }}
                      />
                    </div>
                    {errors.verificador && touched.verificador && (
                      <div className="error" style={{ marginTop: '8px' }}>{String(errors.verificador)}</div>
                    )}
                  </div>
                )}
                {errors.correo && touched.correo
                  ? (<div className="error">{String(errors.correo)}</div>)
                  : null}
                {!emailVerified && values.correo &&
                  (<div className="error">{errorMessage ? errorMessage : "El correo debe ser validado"}</div>)}
              </>
              <>
                <label className="labelForm"><b>Celular</b></label>
                <div className="dataField">
                  <p style={{ marginBottom: '3%' }}>+569</p>
                  <Field id="celular" name="celular" className="formInput" placeholder='87654321' />
                </div>
                {errors.celular && touched.celular
                  ? (<div className="error">{String(errors.celular)}</div>)
                  : null}
              </>
              {dataHour.uid == null ? (
              <div style={{ marginTop: '5%', marginBottom: '2%' }}>
                <ReCAPTCHA
                  sitekey="6LfK0TIqAAAAACUjI8_cU1U0n1JuG6StQ--kMzsD"
                  onChange={handleChangeCaptcha}
                />
                </div>
              ) : null}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Checkbox
                  style={{ color: '#52A92B' }}
                  // inputProps={{ 'aria-label': 'Checkbox demo' }}
                  value={userPermission}
                  onClick={() => {
                    setUserPermission(!userPermission)
                  }}
                  defaultChecked
                  />
                  <a href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL} target="_blank" style={{ textAlign: 'left', fontSize: '12px', marginTop: '1%', marginBottom: '1%' }}>
                  Acepto los términos y condiciones y la política de privacidad de {process.env.REACT_APP_COMPANY_NAME}             </a>
              </div>
              <button
                className="reservationButton"
                type="submit"
                disabled={
                  isLoading ||
                  validatingMail ||
                  !captcha ||
                  !userPermission ||
                  !emailVerified ||
                  !values.correo ||
                  (values.correo !== clientData.correo && !verificarMail)
                }
              >
                {(isLoading)
                  ? <ClipLoader size={15} color="white" />
                  : 'Reservar Hora'
                }
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <PopUp />
    </div>
  );
};

export default ClientDataContainer;
