import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/Confirmation.css';
import 'dayjs/locale/es';
import axios from 'axios';
import { DataHour } from '../components/Interfaces';
import { MdOutlineCalendarMonth, MdPhoneInTalk } from 'react-icons/md';
import { CiClock2, CiHospital1, CiMail, CiStethoscope } from 'react-icons/ci';
import { FaMapMarkerAlt, FaRegIdCard, FaRegUserCircle, FaUserMd } from "react-icons/fa";
import { FaStethoscope, FaUserDoctor } from "react-icons/fa6";
import clockIcon from '../assets/images/Reloj_Verde.png';
import estetoscopioIcon from '../assets/images/Estetoscopio_Verde.png'
import profesionalIcon from '../assets/images/Profesional_Verde.png'
import sucursallIcon from '../assets/images/Sucursal_Verde.png'
import ubicacionIcon from '../assets/images/Ubicación_Verde.png'
import personaIcon from '../assets/images/Persona_Verde.png'
import carnetIcon from '../assets/images/Carnet_Verde.png'
import telefonoIcon from '../assets/images/Telefono_Verde.png'
import checkIcon from '../assets/images/Check_Blanco.png'
import { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';

const baseURL = process.env.REACT_APP_BASEURL;
const authorization = process.env.REACT_APP_AUTHORIZATION;

const ConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { clientData } = location.state;
  const hourData: DataHour = location.state.dataHour;
  const { dataLabels } = location.state;
  const { reservationDate, reservationHour } = location.state;
  const [isLoading, setIsLoading] = useState(false);

  const toHome = () => {
    window.location.replace(`https://${process.env.REACT_APP_URL_WEB}`);
  };
  const toReserve = () => {
    navigate('/filter', { state: { message: `${hourData.idGroupConsultHour}`, clientData } });
  };

  const cancelarHora = async () => {
    setIsLoading(true);
    await axios.post(`${baseURL}/alma/external/v1/confirmationWeb`,
      {
        id_agendamiento: hourData.reservationCode,
        status: 2,
        rut: clientData.rut
      },
      {
        headers: {
          Authorization: authorization,
          'Content-Type': 'multipart/form-data',
        },
      }).catch().then(() => {
        navigate('/cancel-reservation', { state: { id: `${hourData.idGroupConsultHour}`, clientData } });
      });
    setIsLoading(false);
  };

  const formatearFecha = (fecha: string) => {
    const [year, month, day] = fecha.split(' ')[0].split('-');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    const dia = date.getDate().toString().padStart(2, '0');
    const mes = (date.getMonth() + 1).toString().padStart(2, '0');
    const año = date.getFullYear().toString().slice(2, 4);
    return `${dia}/${mes}/${año}`;
  }

  return (
    <div className="appBody">
      <div className="contentConfirmation">
        <div className="reservationTitle">
          <div className="title">Hora reservada con éxito</div>
        </div>
        <div className='titleBody'>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', height: '50%', flex: 5, position: 'relative' }}>
            <div style={{ position: 'absolute', width: '45px', height: '45px', borderRadius: '50%', backgroundColor: 'var(--background-color)', left: '50%', transform: 'translateX(-50%)', top: '-20px' }}>
              <img src={checkIcon} alt='calendar' style={{ width: '90%', height: '90%', borderRadius: '50%', marginLeft: '5%', marginTop: '5%' }} />
            </div>
            {/* <CiClock2 style={{flex: 1}} size={30} color='white'/> */}
            <div className='title'>
              Código de reserva
              <br />
              {hourData.reservationCode}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            {/* <MdOutlineCalendarMonth size={30} color='#51b543' /> */}
            <div style={{ width: '23%', justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
              <MdOutlineCalendarMonth size={50} color='var(--background-color)' />
            </div>
            <div className='titleTextLeft'>
              {formatearFecha(hourData.reservationHour.slice(0, 10))}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
            <div style={{ width: '23%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <CiClock2 size={50} color='var(--background-color)' />
            </div>
            <div className='titleTextRight'>
              {' '}
              {reservationHour}
              {' '}
              hrs
            </div>
          </div>
        </div>
        <div className="dataContainer">
          <div className="reservationData">
            <div className="subTitleContainer">Datos de Atención</div>
            <div className="rowInfo">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%', padding: '2%' }}>
                <FaUserDoctor size={35} color='var(--background-color)' />
              </div>
              <div style={{ flexDirection: 'column', width: '100%' }}>
                <div className='dataLabelTitle'>
                  Profesional
                </div>
                <div>
                  {hourData.profesionalName}
                </div>
              </div>
            </div>
            <div style={{ borderTop: "1px solid var(--background-color)", margin: "0px 0", width: '83%', marginLeft: '13%' }} />
            <div className="rowInfo">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%', padding: '2%' }}>
                <FaStethoscope size={35} color='var(--background-color)' />
              </div>
              <div style={{ flexDirection: 'column', width: '100%' }}>
                <div className='dataLabelTitle'>
                  Prestación
                </div>
                <div>
                  {dataLabels.category.toLowerCase().replace(/\b\w/g, (l: any) => l.toUpperCase())}
                </div>
              </div>
            </div>
            <div style={{ borderTop: "1px solid var(--background-color)", margin: "0px 0", width: '83%', marginLeft: '13%' }} />
            <div className="rowInfo">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%', padding: '2%' }}>
                <CiHospital1 size={35} color='var(--background-color)' />
              </div>
              <div style={{ flexDirection: 'column', width: '100%' }}>
                <div className='dataLabelTitle'>
                  Sucursal
                </div>
                <div>
                  {dataLabels.facility.name}
                </div>
              </div>
            </div>
            <div style={{ borderTop: "1px solid var(--background-color)", margin: "0px 0", width: '83%', marginLeft: '13%' }} />
            <div className="rowInfo">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%', padding: '2%' }}>
                <FaMapMarkerAlt size={35} color='var(--background-color)' />
              </div>
              <div style={{ flexDirection: 'column', width: '100%' }}>
                <div className='dataLabelTitle'>
                  Dirección
                </div>
                <div>
                  {dataLabels.facility.street}
                </div>
              </div>
            </div>
          </div>
          <div className="reservationData">
            <div className="subTitleContainer">Datos del Paciente</div>
            <div className="rowInfo">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%', padding: '2%' }}>
                <FaRegUserCircle size={35} color='var(--background-color)' />
              </div>
              <div style={{ flexDirection: 'column', width: '100%' }}>
                <div className='dataLabelTitle'>
                  Nombre
                </div>
                <div>
                  {clientData.nombre}
                  {' '}
                  {clientData.paterno}
                  {' '}
                  {clientData.materno}
                </div>
              </div>
            </div>
            <div style={{ borderTop: "1px solid var(--background-color)", margin: "0px 0", width: '83%', marginLeft: '13%' }} />
            <div className="rowInfo">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%', padding: '2%' }}>
                <FaRegIdCard size={30} color='var(--background-color)' />
              </div>
              <div style={{ flexDirection: 'column', width: '100%' }}>
                <div className='dataLabelTitle'>
                  RUT
                </div>
                <div>
                  {clientData.rut}
                </div>
              </div>
            </div>
            <div style={{ borderTop: "1px solid var(--background-color)", margin: "0px 0", width: '83%', marginLeft: '13%' }} />
            <div className="rowInfo">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%', padding: '2%', paddingLeft: '1.3%' }}>
                <CiMail size={37} color='var(--background-color)' />
              </div>

              <div style={{ flexDirection: 'column', width: '100%' }}>
                <div className='dataLabelTitle'>
                  Email
                </div>
                <div>
                  {clientData.correo}
                </div>
              </div>
            </div>
            <div style={{ borderTop: "1px solid var(--background-color)", margin: "0px 0", width: '83%', marginLeft: '13%' }} />
            <div className="rowInfo">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%', padding: '2%' }}>
                <MdPhoneInTalk size={35} color='var(--background-color)' />
              </div>
              <div style={{ flexDirection: 'column', width: '100%' }}>
                <div className='dataLabelTitle'>
                  Celular
                </div>
                <div>
                  {clientData.celular}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons">
          <div style={{ width: '100%' }}>
            <button className='containerTopButton' type="submit" onClick={toReserve}>Reservar otra hora</button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', }}>
            <button className='containerBottomButton' type="submit" onClick={cancelarHora}>
              {isLoading
                ?
                <ClipLoader size={15} color="var(--background-color)" />
                :
                'Anular hora'
              }</button>
            <button className='containerBottomButton' type="submit" onClick={toHome}>Volver al inicio</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
